import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    min-height: 320px;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 96px 48px 64px 48px;
    background-color: ${({theme}) => theme.colors.white};

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        padding: 0;

        a {
            font-size: ${({theme}) => theme.fonts.s.tablet};
        }
    }
`;

export const Code = styled.div`
    font-size: 200px;
    font-weight: 700;
    padding: 0px 48px;
    color: ${({theme}) => theme.colors.black};
    font-family: Rajdhani, sans-serif;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        font-size: 140px;
        padding: 0;
    }
`;

export const Text = styled.div`
    margin: 36px 0 36px 0;
    font-size: 20px;
    text-align: center;
    color: ${({theme}) => theme.colors.black};


    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        font-size: 16px;
        margin-top: 16px;
    }
`;
