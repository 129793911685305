import React from "react";
import {PrimaryButton} from "components/button/Button";
import {Code, Container, Text} from "./styles";

const ErrorPage = ({code, text, button}) => (
  <Container>
    <Code>{code}</Code>
    <Text>{text}</Text>
    <PrimaryButton to="/">{button}</PrimaryButton>
  </Container>
);

export default ErrorPage;

