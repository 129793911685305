import * as React from "react";
import SEO from "components/Seo";

import ErrorPage from "sections/ErrorPage/ErrorPage";

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <ErrorPage
      code="404"
      text="Strona o podanym adresie nie istnieje!"
      button="Strona główna"
    />
  </>
);

export default NotFoundPage;
